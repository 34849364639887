 
:root {
    --text-color: #124a63;
    --text-color-secondary: #b3c5cd;
    --primary-color: #21b6b7;

    --background-color: #f9f9f9;
    --background-color-light: white;
    --background-color-dark: #eef3f6;

    --box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

html,
body {
    padding: 0;
    margin: 0;
    background-color: var(--background-color);
    color: var(--text-color);
}

.container {
    /* display: flex; */
    margin-left: 240px;
}

.progress {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;
}

.progress .keys {
    flex: 1;
}

.progress .active-keys {
    flex: 1;
}

.progress .tools {
    flex: 1;
}
