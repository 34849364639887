* {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

.fa fa-question-circle:hover {
  background-color: red ;
  color: red;
}

.btn-copy {
  border-color: #bdbdbd;
  border-radius: 5px;
  padding: 5px 10px;
  /* border: none; */
  background-color: inherit;
  color: var(--text-color);  
}
/* 

.ag-body-viewport {
  overflow-y: scroll !important;
}   */

.txt-key {
  margin-top: 10px;
}

.form-group {
  display: flex;
}

.user-info {
  display: flex;
}

.key-input {
  margin-left: 10px;
}

.modal {
    font-size: 12px;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 25px;
    text-align: center;
    padding: 5px;
    margin-top: 10px;
  }
  .modal > .content {
    width: 200px;
    padding: 20px 20px;
    margin: 0.5rem 10rem
    }
    .modal > .content-search {
      width: 200px;
      padding: 20px 20px;
      margin: 0.5rem
      }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

